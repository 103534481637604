import { useCookies } from 'vue3-cookies';
import CLayerBaseService from '../services/CLayerBaseService';
import CLayerService from '../services/CLayerService';
import StoryphoneService from '../services/StoryphoneService';

const cLayerBaseService = new CLayerBaseService();
const cLayerService = new CLayerService();
const storyphoneService = new StoryphoneService();

export default function IsAuthenticated(to, from, next) {
  const { cookies } = useCookies({ secure: true });

  const storyToken = cookies.get('storyPhoneUserAuthCookie');
  const cLayerUserToken = cookies.get('clUserAuthCookie');
  const cLayerToken = cookies.get('clAuthCookie');
  const cLayerAdminToken = cookies.get('clUserAuthAdminCookie');
  const market = localStorage.getItem('marketId');

  if (storyToken && cLayerUserToken) {
    if (!market) {
      localStorage.setItem('LangCode', 'zh');
      localStorage.setItem('LocationImg', 'chinese');
      localStorage.setItem('LocationName', 'China');
      cLayerService.getMarkets();
      next();
    } else {
      next();
    }
  } else if (!cLayerToken) {
    if (!market) {
      localStorage.setItem('LangCode', 'zh');
      localStorage.setItem('LocationImg', 'chinese');
      localStorage.setItem('LocationName', 'China');
      cLayerService.getMarkets().then(() => {
        cLayerBaseService.createToken().then(() => {
          next();
        });
      });
    } else {
      cLayerBaseService.createToken().then(() => {
        next();
      });
    }
  } else {
    next();
  }
}
